<template>
  <div :class="[`calendar-marker ${color}`, { mini }]" />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'default'
    },
    mini: {
      type: Boolean,
      default: false
    }
  }

}
</script>

<style lang="scss">
.calendar-marker {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;

  &.mini {
    height: 6px;
    width: 6px;
  }

  &.default {
    background-color: var(--dividers_high_contrast);
  }

  &.warning {
    background-color: var(--accent_warning);
  }

  &.error {
    background-color: var(--accent_error);
  }
}
</style>
